import React from "react";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import Layout from "~/components/layout";
import SEO from "~/components/seo";

const AboutUsPage = ({ i18n, pageContext }) => {
  const paths = pageContext.paths;

  return (
    <Layout paths={paths}>
      <SEO
        title={i18n._(t`About us`)}
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        location={paths}
      />

      <div>
        <div className="md:mr-auto md:ml-auto lg:max-w-6xl">
          <div className="mt-20 md:mt-24 ml-5 mb-8 text-xl font-medium md:text-3xl">
            <Trans>About us</Trans>
          </div>
          <div>
            <img
              srcSet="
                    https://res.cloudinary.com/ie1/image/upload/w_320,f_auto,q_auto:best/v1586435863/vtutor/about-us.jpg 320w,
                    https://res.cloudinary.com/ie1/image/upload/w_375,f_auto,q_auto:best/v1586435863/vtutor/about-us.jpg 375w,
                    https://res.cloudinary.com/ie1/image/upload/w_768,f_auto,q_auto:best/v1586435863/vtutor/about-us.jpg 768w,
                    https://res.cloudinary.com/ie1/image/upload/w_1024,f_auto,q_auto:best/v1586435863/vtutor/about-us.jpg 1024w,
                    https://res.cloudinary.com/ie1/image/upload/w_1280,f_auto,fl_progressive:steep,q_auto:best/v1586435863/vtutor/about-us.jpg 1280w
                    "
              sizes="(max-width: 320px) 320px,(max-width: 412px) 375px,(max-width: 800px) 768px, (max-width: 1050px) 1024px, 1280px"
              src="https://res.cloudinary.com/ie1/image/upload/w_1280,f_auto,fl_progressive:steep,q_auto:best/v1586435863/vtutor/about-us.jpg"
              alt={i18n._(t`About us`)}
              className="md:h-64 md:object-cover w-full"
            />
          </div>
          <div className="p-5 mt-2  md:mt-8">
            <div className="text-sm text-brand-link-gray mb-4 md:mb-6 lg:pb-10 lg:text-lg md:px-6 lg:px-8">
              <Trans>
                Developed by a highly skilled team with extensive experience in
                the fields of education, digital marketing and engineering, our
                mission is to make the learning process easy and accessible to
                everyone.
                <br />
                <br />
                vTutor offers professional development through a video tutorial
                platform, with courses designed and developed by experts all
                over the world in a wide array of subjects.
                <br />
                <br />
                Breaking down time and location constraints, vTutor’s
                cutting-edge in-house technology removes potential barriers to
                learning, such as time and location, and allows tutors and
                students to connect in a truly global fashion.
              </Trans>
            </div>
            <div className="md:flex md:justify-between md:p-6 lg:p-8 lx:p-12">
              <div className="font-medium mb-3 md:text-xl lg:text-2xl md:text-right">
                <Trans>Our goal</Trans>
              </div>
              <p className="text-sm text-brand-link-gray border-b border-gray-400 pb-4 md:pb-8 lg:pb-12 md:flex-1 md:ml-6 lg:ml-8 xl:ml-12 lg:text-base md:max-w-3/4">
                <Trans>
                  is to become a leader in modern learning by providing one of
                  the most sophisticated online learning platforms in the
                  market. We aim to allow millions of students, regardless of
                  location, to have access to the highest quality educational
                  content and support.
                </Trans>
              </p>
            </div>
            <div className="pt-4 md:pt-3 md:flex md:justify-between md:p-6 lg:p-8 lx:p-12">
              <div className="font-medium mb-3 md:text-xl lg:text-2xl">
                <Trans>At vTutor</Trans>
              </div>
              <p className="text-sm text-brand-link-gray border-b border-gray-400 pb-4 md:pb-8 lg:pb-12  md:flex-1 md:ml-6 lg:ml-8 xl:ml-12 lg:text-base md:max-w-3/4">
                <Trans>
                  we strive for equality of opportunity by supporting the
                  intellectual, personal and professional growth of learners
                  around the world.
                </Trans>
              </p>
            </div>
            <div className="pt-4 md:pt-3 md:flex md:justify-between md:p-6 lg:p-8 lx:p-12">
              <div className="font-medium mb-3  md:text-xl lg:text-2xl">
                <Trans>We offer</Trans>
              </div>
              <p className="text-sm text-brand-link-gray border-b border-gray-400 pb-4 md:pb-8 lg:pb-12  md:flex-1 md:ml-6 lg:ml-8 xl:ml-12 lg:text-base md:max-w-3/4">
                <Trans>
                  a diverse user friendly platform and pride ourselves on our
                  first-in-class client service, providing an unbeata experience
                  to both learners and tutors alike.
                </Trans>
              </p>
            </div>
            <div className="pt-4 md:pt-3 md:flex md:justify-between md:p-6 lg:p-8 lx:p-12">
              <div className="font-medium mb-3 md:text-xl lg:text-2xl">
                <Trans>Collaboration</Trans>
              </div>
              <p className="text-sm text-brand-link-gray border-b border-gray-400 pb-4 md:pb-8 lg:pb-12  md:flex-1 md:ml-6 lg:ml-8 xl:ml-12 lg:text-base md:max-w-3/4">
                <Trans>
                  is vital to our success and we are constantly grateful for the
                  support of our learners and tutors. We promise our community
                  to consistently improve our learning platform, making every
                  day a day for success.
                </Trans>
              </p>
            </div>
            <div className="text-right mt-4 md:mb-4 text-sm text-brand-link-gray lg:text-base md:mr-6 font-medium">
              <div className="max-w-1/2 inline-block">
                <p>
                  <Trans>
                    vTutor, teaching innovatively, learning what matters.
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withI18n()(AboutUsPage);
